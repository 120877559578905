import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

export interface ImageCardProps {
  image: IGatsbyImageData;
  title: string;
}

const ImageCard: React.FC<ImageCardProps> = ({ image, title }) => {
  return (
    <div className="flex flex-col w-full p-2 overflow-hidden border border-gray-100 shadow-lg rounded-2xl">
      <GatsbyImage
        image={image}
        className="w-full h-full"
        imgClassName="rounded-lg"
        alt={title}
      />
      <div className="flex justify-center py-3 mt-2 font-bold text-primary">
        {title}
      </div>
    </div>
  );
};

export default ImageCard;
