import classNames from "classnames";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import * as React from "react";
import { useState } from "react";
import Testimonial, { TestimonialProps } from "./Testimonial";

export interface TestimonialsSectionProps {
  testimonials: {
    edges: {
      node: TestimonialProps;
    }[];
  };
}

const TestimonialsSection: React.FC<TestimonialsSectionProps> = ({
  testimonials,
}) => {
  return (
    <div className="">
      <h1 className="pb-12 text-3xl font-bold font-headline text-primary">
        References
      </h1>

      {testimonials && <Slider testimonials={testimonials} />}
    </div>
  );
};

export default TestimonialsSection;

const Slider: React.FC<TestimonialsSectionProps> = ({ testimonials }) => {
  const [pause, setPause] = React.useState(false);
  const timer = React.useRef<NodeJS.Timeout | null>(null);
  const [slideNum, setSlideNum] = useState(0);

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 1,
      spacing: 40,
    },
    mode: "snap",
    loop: true,
    breakpoints: {
      "(min-width: 768px)": {
        slides: {
          perView: 2,
          spacing: 40,
        },
      },
    },
    slideChanged: (s) => {
      setSlideNum(s.track.details.rel);
    },
    dragStarted: () => {
      setPause(true);
    },
    dragEnded: () => {
      setPause(false);
    },
  });

  React.useEffect(() => {
    if (!slider.current) {
      return;
    }
    const el = slider.current.container;

    const over = () => setPause(true);
    const out = () => setPause(false);

    el.addEventListener("mouseover", over);
    el.addEventListener("mouseout", out);
    return () => {
      el.removeEventListener("mouseover", over);
      el.removeEventListener("mouseout", out);
    };
  }, [sliderRef]);

  return (
    <>
      <div className="w-full keen-slider" ref={sliderRef}>
        {testimonials.edges.map((v, i) => (
          <div className="keen-slider__slide" key={i}>
            <Testimonial
              id={v.node.id}
              key={v.node.id}
              name={v.node.name}
              role={v.node.role}
              quote={v.node.quote}
              image={v.node.image}
            />
          </div>
        ))}
      </div>
      {testimonials.edges.length > 1 && slider && (
        <div className="flex flex-row justify-center w-full mt-12 space-x-4">
          {testimonials.edges.map((_, i) => (
            <div
              key={i}
              className={classNames(
                "w-2 h-2 rounded-full",
                i == slideNum ? "bg-green-300" : "bg-gray-200"
              )}
            />
          ))}
        </div>
      )}
    </>
  );
};
