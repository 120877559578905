import BlockContent, {
  BlockContentProps,
} from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import ImageWithCaption from "../components/blocks/ImageWithCaption";
import ImageCard from "../components/cards/ImageCard";
import SocialMediaTeaser from "../components/SocialMediaTeaser";
import TestimonialsSection, {
  TestimonialsSectionProps,
} from "../components/TestimonialsSection";
import { useGlobalState } from "../state/globalState";

export interface BecomeAPartnerProps {
  data: {
    site: {
      siteMetadata: {
        mailContact: string;
      };
    };
    partner: {
      title: string;
      overline: string;
      _rawBody: string;
    };
    testimonials: TestimonialsSectionProps["testimonials"];
    energyImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    mobilityImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    nutritionImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    consumptionImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    dataImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    offsettingImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    ngoImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
}

const BecomeAPartner: React.FC<BecomeAPartnerProps> = ({
  data: {
    site: {
      siteMetadata: { mailContact },
    },
    partner,
    testimonials,
    energyImage,
    mobilityImage,
    nutritionImage,
    consumptionImage,
    dataImage,
    offsettingImage,
    ngoImage,
  },
}) => {
  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "light",
      hidden: false,
    });
  }, []);

  const serializers = React.useMemo<BlockContentProps["serializers"]>(
    () => ({
      types: {
        imageWithCaption: ({ node }) => {
          return <ImageWithCaption {...node} />;
        },
      },
    }),
    []
  );

  const { t } = useTranslation(["become-a-partner"]);

  return (
    <>
      <div>
        <section className="flex flex-col justify-center pt-32 mx-auto container-4xl">
          <div className="z-10">
            <h1 className="text-5xl md:text-6xl lg:text-6.5xl leading-none text-left text-gray-500 font-black uppercase">
              <Trans i18nKey="become-a-partner:title">
                <span className="inline-block max-w-full font-black text-purple-500 break-words with-hyphens">
                  CO₂
                </span>
                down <span className="font-black">&nbsp;</span>
                <span className="inline-block max-w-full font-black break-words text-amber-500 with-hyphens">
                  Karma
                </span>
                up.
              </Trans>
            </h1>
          </div>
        </section>

        <div className="px-4 pb-16 container-4xl">
          <div className="prose-lg text-gray-500">
            <BlockContent blocks={partner._rawBody} serializers={serializers} />
          </div>

          <hr className="my-12" />

          <div className="space-y-12">
            <div className="absolute -mt-32" id="brands" />
            <div className="space-y-4">
              <h3 className="mb-4 text-2xl text-primary font-headline">
                {t("become-a-partner:brand_partnerships.title")}
              </h3>
              <p className="leading-6 text-gray-500">
                {t("become-a-partner:brand_partnerships.description")}
              </p>
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <ImageCard
                image={energyImage.childImageSharp.gatsbyImageData}
                title={t(
                  `become-a-partner:brand_partnerships.industries.energy`
                )}
              />
              <ImageCard
                image={mobilityImage.childImageSharp.gatsbyImageData}
                title={t(
                  `become-a-partner:brand_partnerships.industries.mobility`
                )}
              />
              <ImageCard
                image={nutritionImage.childImageSharp.gatsbyImageData}
                title={t(
                  `become-a-partner:brand_partnerships.industries.nutrition`
                )}
              />
              <ImageCard
                image={consumptionImage.childImageSharp.gatsbyImageData}
                title={t(
                  `become-a-partner:brand_partnerships.industries.consumption`
                )}
              />
            </div>

            <div className="flex justify-center w-full mx-auto">
              <a
                href={`mailto:${mailContact}?subject=B2B-Partnership`}
                className="button-green-filled"
              >
                {t("become-a-partner:brand_partnerships.cta")}
              </a>
            </div>
          </div>

          <hr className="my-12" />

          <div className="space-y-12">
            <div className="absolute -mt-32" id="organizations" />
            <div className="space-y-4">
              <h3 className="mb-4 text-2xl mt-14 font-headline text-primary">
                {t("become-a-partner:organization_partnerships.title")}
              </h3>
              <p className="leading-6 text-gray-500">
                {t("become-a-partner:organization_partnerships.description")}
              </p>
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <ImageCard
                image={dataImage.childImageSharp.gatsbyImageData}
                title={t(
                  `become-a-partner:organization_partnerships.industries.data`
                )}
              />
              <ImageCard
                image={offsettingImage.childImageSharp.gatsbyImageData}
                title={t(
                  `become-a-partner:organization_partnerships.industries.offsetting`
                )}
              />
              <ImageCard
                image={ngoImage.childImageSharp.gatsbyImageData}
                title={t(
                  `become-a-partner:organization_partnerships.industries.ngo`
                )}
              />
            </div>

            <div className="flex justify-center w-full mx-auto">
              <a
                href={`mailto:${mailContact}?subject=NGO-Partnership`}
                className="button-green-filled"
              >
                {t("become-a-partner:organization_partnerships.cta")}
              </a>
            </div>
          </div>
        </div>

        <div className="py-12 bg-gray-100">
          <SocialMediaTeaser />
        </div>

        <div className="py-12 container-4xl">
          <TestimonialsSection testimonials={testimonials} />
        </div>
      </div>
    </>
  );
};

export default BecomeAPartner;

export const query = graphql`
  query BecomeAPartnerQuery($language: String!) {
    site {
      siteMetadata {
        mailContact
      }
    }
    partner: sanitySimplePage(
      slug: { current: { eq: "partner" } }
      i18n_lang: { eq: $language }
    ) {
      title
      overline
      _rawBody
    }
    testimonials: allSanityTestimonial(
      filter: { i18n_lang: { eq: $language } }
    ) {
      edges {
        node {
          id
          name
          role
          quote
          image {
            asset {
              # gatsbyImageData(width: 300, placeholder: BLURRED)
              localFile(width: 128) {
                childImageSharp {
                  gatsbyImageData(width: 64, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
    energyImage: file(relativePath: { eq: "images/card-images/energy.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 100 }
        )
      }
    }
    mobilityImage: file(
      relativePath: { eq: "images/card-images/mobility.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 100 }
        )
      }
    }
    nutritionImage: file(
      relativePath: { eq: "images/card-images/nutrition.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 100 }
        )
      }
    }
    consumptionImage: file(
      relativePath: { eq: "images/card-images/consumption.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 100 }
        )
      }
    }
    dataImage: file(relativePath: { eq: "images/card-images/data.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 100 }
        )
      }
    }
    offsettingImage: file(
      relativePath: { eq: "images/card-images/offsetting.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 100 }
        )
      }
    }
    ngoImage: file(relativePath: { eq: "images/card-images/ngo.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 100 }
        )
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
