import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

export interface TestimonialProps {
  id: string;
  name: string;
  role: string;
  quote: string;
  image: {
    asset: {
      // gatsbyImageData: IGatsbyImageData;
      localFile: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    };
  };
}

const Testimonial: React.FC<TestimonialProps> = ({
  name,
  image,
  role,
  quote,
}) => {
  return (
    <div className="flex flex-col h-full space-y-6">
      <span className="flex-grow italic leading-6 tracking-wide">
        "{quote}"
      </span>
      <hr />
      <div className="flex flex-row items-center space-x-4">
        <GatsbyImage
          className="flex-shrink-0 object-fill w-16 h-16 rounded-full"
          image={image.asset.localFile.childImageSharp.gatsbyImageData}
          imgClassName="rounded-full"
          alt={name}
        />
        <div className="flex flex-col space-y-1 font-headline">
          <span className="text-sm font-bold text-primary">{name}</span>
          <span className="text-xs font-bold tracking-widest text-gray-500 uppercase font-headline">
            {role}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
